<template>
    <div class="mr-6">
        <hb-data-table-header left-columns="12" right-columns="0">
            <template v-slot:header> {{ automationInfo.name }} </template>
            <template v-slot:description>
                {{ template === AUTOMATION.MENU_TABS.CORPORATE ? automationInfo.corporate_description : automationInfo.property_description}}
            </template>
        </hb-data-table-header>
    </div>
</template>

<script type="text/babel">
import AutomationAccordian from "./AutomationAccordian.vue";
import AUTOMATION from '@/constants/automation.js';

export default {
  name: "AutomationHeader",
  data() {
    return {
      states: [],
      isStatesLoading: true,
    };
  },
  props: {
    automationInfo:
      {
        type: Object,
        default() {
          return {};
        }
      },
      template:
      {
        type: String,
        default: null
      }
  },
  components: {
    AutomationAccordian
  },
  computed: {
  },
  created() {
    this.AUTOMATION = AUTOMATION;
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped></style>