<template>
    <hb-modal size="medium" :title="title" v-model="dialog" confirmation @close="$emit('close')">
        <template v-slot:content>
            <div class="py-4 px-6">
                <div class="sub_title mb-2" v-html="sub_title"></div>
                <div class="description" v-html="description"></div>
                <v-list v-if="actions.length > 0">
                    <v-list-item v-for="(a, i) in actions" :key="i" @click="$emit('applyActions', actions)"
                        style="min-height: 25px !important;" class="px-0">
                        <v-list-item-title class="d-flex align-center">
                            <HbCheckbox v-model="a.active" :label="a.name" class="pr-2" /> <span class="process">
                                <HbIcon mdi-code="mdi-check-circle" small
                                    :color="a.icon_context === 'corporate' ? '#02AD0F' : '#FFD600'" /> {{ a.process_name
                                }}
                            </span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('ok')" :disabled="disabled" :loading="disabled">{{ AUTOMATION.APPLY
                }}</hb-btn>
        </template>
    </hb-modal>
</template>

<script type="text/babel">
import AUTOMATION from '@/constants/automation.js';
export default {
    name: 'ApplyWorkflow',
    data() {
        return {
        };
    },
    async created() {
        this.AUTOMATION = AUTOMATION;
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    props: {
        title: {
            type: String,
            default() {
                return 'Apply';
            }
        },
        sub_title: {
            type: String,
            default() {
                return 'Automation > Type';
            }
        },
        description: {
            type: String,
            default() {
                return 'Select the workflow.';
            }
        },
        actions: {
            type: Array,
            default() {
                return [];
            }
        },
        value: {
            type: Boolean,
            default() {
                return false;
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.sub_title,
.process {
    font-weight: 500;
}
</style>